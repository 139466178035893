import {Injectable, Injector} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {HealthResponse} from '../../../../shared/models/response';
import {BaseService} from '../../../services/base.service';

@Injectable()
export class ActuatorService extends BaseService {

  constructor(injector: Injector) {
    super('actuator', injector);
  }

  fetchHealthStatus(): Observable<HealthResponse> {
    console.log('Fetching health status!!!!!!!!!!!!!!!!' + this.urlPrefix);
    return this.httpClient.get<HealthResponse>(`${environment.baseUrl}/actuator/health/liveness`);
  }
}


