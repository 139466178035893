import { Component, Inject, OnInit } from '@angular/core';

import { difference } from 'lodash';
import { MenuItem, Permission } from '../../../shared/models/common';
import {HealthStatus} from '../../../shared/models/response';
import { AuthService } from '../../services/auth.service';
import { APP_CONFIG, AppConfig } from '../../tokens/app-config.token';
import {ActuatorService} from './services/actuator.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {

  public menuItems: MenuItem[];
  healthStatus: HealthStatus | null = null;

  private userPermissions: Permission[] = [];

  constructor(@Inject(APP_CONFIG) appConfig: AppConfig, private authService: AuthService, private actuatorService: ActuatorService) {
    this.menuItems = appConfig['menuItems'];
  }

  ngOnInit() {
    this.actuatorService.fetchHealthStatus().subscribe({
      next: (response) => {
        this.healthStatus = response.status;
      },
      error: (err) => {
        console.error('Failed to fetch health status', err);
        this.healthStatus = HealthStatus.UNKNOWN;
      },
    });
    this.authService.getPermissions().subscribe(userPermissions => {
      this.userPermissions = userPermissions;
    });
  }

  hasPermissions(permissions?: Permission[]): boolean {
    return difference(permissions, this.userPermissions).length === 0;
  }
}
