<div class="menu-wrapper">
  <ng-container *ngFor="let menu of menuItems">
    <a
      class="menu-item"
      *ngIf="hasPermissions(menu.permissions) && !menu.subMenus"
      mat-flat-button
      [routerLink]="menu.href"
      color="accent">{{ menu.text | translate }}</a>
    <a
      class="menu-item"
      *ngIf="hasPermissions(menu.permissions) && menu.subMenus"
      mat-flat-button
      color="accent"
      [matMenuTriggerFor]="subMenu"
      >{{ menu.text | translate }}</a>
    <mat-menu
      #subMenu="matMenu">
      <ng-container *ngFor="let subMenu of menu.subMenus">
        <a
          *ngIf="hasPermissions(subMenu.permissions)"
          [routerLink]="subMenu.href"
          mat-menu-item>{{ subMenu.text | translate }}</a>
      </ng-container>
    </mat-menu>
  </ng-container>
    <ng-container>
      <div class="text-container">
        <h3 style="font-weight: bold">{{'APP.MENU.BACKEND_STATUS' | translate}}
          <mat-icon style="vertical-align: middle; margin-left: 8px; color: #0b8319">
            {{ healthStatus === 'UP' ? 'check_circle_outline' : 'highlight_off' }}
          </mat-icon>
        </h3>
      </div>
    </ng-container>
</div>
